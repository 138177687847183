<!-- 热门回收 -->
<template>
    <div class="flex XCenter hostCard">
        <el-card class="box-card" v-for="item in list" :key="item.id">
            <div class="flexColumn XCenter YCenter h100 m5" @click='jumpCardRecycle(item)'>
                <img class="image" :src="item.gc_cover" alt="" srcset="">
                <p class="cardName mT15">{{ item.gc_name }}</p>
                <p class="mT15 discountText">{{ item.less_cost_discount }}--{{ item.high_cost_discount }}折收购</p>
                <el-button class="mT15" type="danger" round>我要销卡</el-button>
            </div>
        </el-card>
        <div class="showMore" @click="showMore"><i class="el-icon-caret-bottom" v-if="show"></i>
            <span>{{ show ? '展示更多' : '暂无更多' }}</span>
        </div>
        <el-empty v-if="list.length < 1" description="暂无数据"></el-empty>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            show: false,
            reqList: [],
            catchIndex: 10
        };
    },

    components: {},

    mounted() {
        this.getList()
    },

    methods: {
        showMore() {
            if (this.reqList.length > this.catchIndex) {

                let arr = this.reqList.slice(this.catchIndex, this.catchIndex + 10)
                this.list = this.list.concat(arr)
                this.catchIndex += 10
                if (this.reqList.length < this.catchIndex) {
                    this.show = false
                }
            }

        },
        jumpCardRecycle(row) {
            this.$router.push({ name: "cardRecycle", params: row },)
        },
        getList() {
            this.$axios.get("/api/shop/getHotGoodsClass").then((res) => {
                console.log(res)

                if (res.code == "000") {
                    this.reqList = res.data
                    if (res.data.length > 10) {
                        this.show = true
                        let arr = res.data.slice(0, this.catchIndex)
                        this.list = arr
                    } else {
                        this.list = res.data;
                    }

                }
            });
        }
    }
}

</script>
<style lang="scss" >
.hostCard {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 80px;


    .box-card {
        margin: 20px 3% 15px;
        width: 210px;
        height: 300px;
        background-color: #fafafa;
    }

    .image {
        width: 100%;
        height: 150px;
        border-radius: 5px;
        border: 1px solid #f5f5f5
    }

    .cardName {
        font-size: 18px;
    }

    .discountText {
        font-size: 18px;
        color: #f40;
    }

    .showMore {
        width: 100%;
        text-align: center;
        cursor: pointer
    }

    .showMore:hover {
        color: $themeColor;
    }

    .el-card__body {
        padding: 0px;
    }

    .mT15 {
        margin: 15px 0 0 0;
    }

    .m5 {
        margin: 5px;
    }
}
</style>