<!-- 首页 -->
<template>
  <div class="home">
    <!-- <el-carousel height="350px">
      <el-carousel-item v-for="(item, index) in carouselImageList" :key="index">
        <img class="w100 h100" :src="item.src" alt="" srcset="" />
      </el-carousel-item>
    </el-carousel> -->
    <!-- <button class="jumpCardRecycleButton" @click="jumpCardRecycle">
      我要销卡
    </button> -->

    <div class="search">
      <!-- <el-select class="searchInput" v-model="value" reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod"
        :loading="loading" filterable>
        <el-option v-for="item in searchOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select> -->
      <el-select class="searchInput" size="medium" value-key="gc_id" v-model="value" filterable remote reserve-keyword
        placeholder="请输入卡名进行搜索" :remote-method="remoteMethod" :loading="loading" @change="jumpCardRecycle">
        <el-option v-for="(item, index) in searchOptions" :key="item.gc_id" :label="item.gc_name" :value="index">
        </el-option>
      </el-select>
      <span class="searchButton"> <i class="el-icon-search" style="margin-right: 5px;"></i>搜索</span>
    </div>
    <div class="XYCenter tagFather">
      <div class="tag flexColumn YCenter">
        <i class="el-icon-alarm-clock tagIcon"></i><span>急速到账</span>
      </div>
      <div class="tag flexColumn YCenter">
        <i class="el-icon-cold-drink tagIcon"></i><span>安全可靠</span>
      </div>
      <div class="tag flexColumn YCenter">
        <i class="el-icon-bank-card tagIcon"></i>
        <span>高价回收</span>
      </div>
    </div>

    <el-divider content-position="center">热门回收</el-divider>
    <HotCard></HotCard>
    <el-divider content-position="center">平台资讯</el-divider>
    <SellCardProcess></SellCardProcess>
    <el-divider content-position="center">公司简介</el-divider>
    <div class="companyProfile">
      <!-- <header>公司简介</header> -->
      <p>
        深圳玮杰信息科技有限公司，成立于2016年，是国内知名的数字商品交易技术服务公司。公司秉承“诚信经营，客户第一”服务理念，持续深耕数字商品交易领域，以过硬的技术实力，建立了连接商家与用户的数字商品流转桥梁。公司先后荣膺国家级高新技术企业、ISO2000信息技术服务管理体系认证企业。
      </p>

      <p>
        公司自主研发的数字商品交易撮合平台——趣卡收卡，于2016年开始经营，是一家集数字商品权益交易、卡券回收、数字商品代充SAAS服务、虚拟商品零售的综合性平台。我们依托数字商品营销的服务场景，为合作伙伴提供数字商品（话费、加油、积分、音视频会员等）交易、数字商品代充技术等增值服务，促进了行业的数字商品高效流转、精准匹配和技术难点攻克，在业内承担着不可或缺的角色。
      </p>

      <p> 未来，我们将持续服务银行、保险、电商等行业的营销场景，为合作伙伴持续创造更多的商业价值。</p>
    </div>
    <notLoginTips></notLoginTips>
  </div>
</template>

<script>
import HotCard from "./component/hotCard.vue";
import SellCardProcess from "./component/sellCardProcess.vue";
import notLoginTips from "../../components/notLoginTips.vue";
export default {
  data() {
    return {
      searchOptions: [],
      value: '',
      loading: false,
      carouselImageList: [
        {
          src: require("../../assets/images/homeBG.png"),
        },
      ],
    };
  },

  components: { HotCard, SellCardProcess, notLoginTips },

  mounted() { },

  methods: {
    jumpCardRecycle(value) {
      console.log(value)
      this.$router.push({ name: "cardRecycle", params: this.searchOptions[value] },)
      // this.$router.push({ path: "/cardRecycle" });
    },
    async remoteMethod(query) {

      if (query !== '') {
        this.loading = true;
        let res = await this.$axios
          .get("/api/shop/getGoodsClassList", {
            params: {
              key: query,
              gc_type: 0,
            },
          })
        this.loading = false

        if (res.code == "000") {
          this.searchOptions = res.data;
        }
        console.log(this.searchOptions)
      }
    }
  },
};
</script>
<style lang="scss" >
.home {
  position: relative;

  .el-divider__text {
    background: $bgColor;
  }

  .tagFather {
    padding: 10px;

    .tag {
      font-size: 15px;
      margin: 20px 90px;
    }

    .tagIcon {
      font-size: 40px;
      margin-bottom: 10px;
      color: $themeColor;
    }
  }

  .jumpCardRecycleButton {
    position: absolute;
    top: 267px;
    z-index: 999;
    right: 45%;
    width: 150px;
    height: 45px;
    font-size: 18px;
    border: none;
    background: #f8c530a1;
    border-radius: 23px;
    color: #fff;
  }

  .search {
    height: 300px;
    background: #990005;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/images/homeBG.png');
    background-size: 100% 100%;

    .searchButton {
      display: inline-block;
      width: 100px;
      height: 49px;
      line-height: 49px;
      background-color: #fff;
      border-radius: 4px;
      font-size: 20px;
      text-align: center;
    }
  }

  .searchInput {

    width: 500px;

    .searchButton {
      display: inline-block;
      width: 100px;
      height: 50px;
      line-height: 50px;
      background-color: #fff;
    }
  }

  .companyProfile {
    font-size: 18px;
    line-height: 27px;
    padding: 30px 10px 30px 10px;
    background: #ffffff;
    color: #000000;
    border-bottom: 1px solid #fff;
    font-weight: 400;

    header {
      font-size: 30px;
      text-align: center;
    }

    p {
      margin: 20px 0 0px 0;
    }
  }



  .el-input--medium .el-input__inner {
    height: 50px;
    line-height: 50px;
  }
}
</style>
