<!-- 收卡流程 -->
<template>
    <div class="sellCardProcess YCenter">
        <div class="announcement">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span class="announcementHeader"><i class="el-icon-message-solid"></i>
                        公告</span>
                    <el-button style="float: right; padding: 3px 0" type="text" @click="jumpAnnouncement">查看更多>></el-button>
                </div>

                <div class="main">
                    <p class="title">{{ annmouncement.art_title }}</p>
                    <p class="content">{{ annmouncement.art_content }}</p>
                </div>
                <div class="time"> {{ annmouncement.art_addtime }} </div>

            </el-card>
        </div>
        <div class="sellCardProcessRight">
            <div class="record"> <el-carousel height="30px" direction="vertical" :autoplay="true">
                    <el-carousel-item v-for="(item, index) in scrollList" :key="index">
                        <h3 class="medium"><span>用户：<span>{{ item.u_ali_realname }} &nbsp; </span><span>提现&nbsp; {{
                            item.pr_price
                        }}</span>
                            </span><span>[{{
                                item.pr_addtime }}]</span></h3>
                    </el-carousel-item>
                </el-carousel></div>
            <div class="process">
                <div class="step1"> <el-steps :active="4" simple>
                        <el-step title="1、登录注册" icon="el-icon-edit"></el-step>
                        <el-step title="2、绑定收款账号" icon="el-icon-edit"></el-step>
                        <el-step title="3、提交卡号卡密" icon="el-icon-upload"></el-step>

                    </el-steps></div>
                <div class="curve"> </div>
                <div class="step2">
                    <el-steps :active="2" simple>
                        <el-step title="4、等待卡密验证" icon="el-icon-picture"></el-step>
                        <el-step title="5、资金到账成功" icon="el-icon-picture"></el-step>
                    </el-steps>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            scrollList: [],
            annmouncement: {}
        };
    },

    components: {},
    filters: {
        ts2Date: (timestamp) => {
            if (timestamp == 0) {
                return "";
            }
            let date = new Date(timestamp * 1000), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
                Y = date.getFullYear() + "-",
                M =
                    (date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1) + "-",
                D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ",
                h =
                    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
                    ":",
                m =
                    (date.getMinutes() < 10
                        ? "0" + date.getMinutes()
                        : date.getMinutes()) + ":",
                s =
                    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },
    },
    mounted() {
        this.getscroll();
        this.getAnnmouncementList()
    },

    methods: {
        jumpAnnouncement() {
            this.$router.push({
                path: '/announcement'
            })
        },
        getscroll() {
            this.$axios.post("/api/shop/getRandPaymentRecord").then((res) => {
                if (res.code == "000") {
                    this.scrollList = res.data;
                }
            });
        },
        async getAnnmouncementList() {
            let res = await this.$axios
                .get("/api/Web/getArticleList", {
                    params: {
                        arc_code: "notice",
                        limit: 20
                    },
                })

            this.annmouncement = res.data.rows[0]
        }
    }
}

</script>
<style lang="scss" >
.sellCardProcess {
    height: 500px;
    display: flex;

    .announcement {
        width: 30%;
        margin: 0px 0 0 50px;

        .announcementHeader {
            font-size: 20px;

            i {
                color: #ff0000;
            }
        }

        .main {
            height: 240px;

            .title {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 15px;
            }

            .content {
                // width: 90%;
                font-size: 14px;
                line-height: 25px;
                color: #5f5f5f;
            }


        }

        .time {
            text-align: right;
        }
    }

    .sellCardProcessRight {
        margin-left: 200px;
        width: 45%;
    }

    .process {
        height: 230px;
        margin-top: 20px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 #00000015;

        .step1 {
            width: 100%;
            padding-top: 20px;
        }

        .curve {
            width: 100%;
            height: 60px;

        }


        .step2 {
            width: 80%;
        }
    }

    .record {
        width: 100%;
        height: 30px;
        color: #fff;
        border-radius: 5px;
        background-color: $themeColor;
    }

    .medium {
        display: flex;
        justify-content: space-around;
        height: 30px;
        line-height: 30px;
        width: 100%;
    }

    .el-carousel__indicators {
        display: none;
    }

    .el-steps--simple {
        background: #fff;
    }

}
</style>